import React from 'react';

function BigLockerLogin(props:any) {
    return (
        <svg {...props} width="428" height="336" viewBox="0 0 428 336" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M283.057 5.23938H145.807C68.9851 5.23938 6.70898 67.5155 6.70898 144.337V190.611C6.70898 267.432 68.9851 329.708 145.807 329.708H283.057C359.878 329.708 422.154 267.432 422.154 190.611V144.337C422.154 67.5155 359.878 5.23938 283.057 5.23938Z"
                fill="#03134C"/>
            <path
                d="M215.723 65.1225H295.203C308.22 64.927 321.107 67.7253 332.871 73.3014C343.892 78.6304 353.672 86.2123 361.58 95.5571C369.685 105.177 375.904 116.238 379.913 128.162C388.548 154.321 388.441 182.578 379.607 208.671C375.443 220.655 369.085 231.758 360.857 241.415C352.763 250.883 342.686 258.457 331.341 263.601C319.996 268.745 307.659 271.333 295.203 271.182H215.723V65.1225ZM250.553 241.053H286.885C295.614 241.131 304.27 239.456 312.34 236.129C320.095 232.901 327.054 228.021 332.732 221.83C338.707 215.19 343.26 207.399 346.113 198.934C349.426 189.002 351.035 178.58 350.87 168.11C351.087 157.055 349.48 146.039 346.113 135.506C343.371 127.033 338.803 119.265 332.732 112.75C327.034 106.837 320.058 102.308 312.34 99.5074C304.178 96.5691 295.559 95.109 286.885 95.1954H250.553V241.053Z"
                fill="white"/>
            <path
                d="M157.191 288.986C224.287 288.986 278.679 234.594 278.679 167.498C278.679 100.402 224.287 46.0105 157.191 46.0105C90.0951 46.0105 35.7031 100.402 35.7031 167.498C35.7031 234.594 90.0951 288.986 157.191 288.986Z"
                fill="white" stroke="#1B2241" strokeWidth="5.74359" strokeMiterlimit="10"/>
            <path
                d="M157.19 258.83C208.86 258.83 250.747 216.943 250.747 165.273C250.747 113.603 208.86 71.7157 157.19 71.7157C105.52 71.7157 63.6328 113.603 63.6328 165.273C63.6328 216.943 105.52 258.83 157.19 258.83Z"
                fill="white" stroke="#1C2242" strokeWidth="5.74359" strokeMiterlimit="10"/>
            <path
                d="M192.661 129.831C177.027 131.166 165.12 127.383 157.191 118.23C149.235 127.383 137.328 131.166 121.694 129.831C120.136 175.761 133.406 201.271 157.191 212.288C180.949 201.271 194.219 175.761 192.661 129.831Z"
                fill="#1C2242"/>
        </svg>
    );
}

export default BigLockerLogin;