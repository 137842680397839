import React from 'react';

function LittleLogoMenuDeskSvg(props:any) {
    return (
        <svg {...props} width="58" height="38.563" viewBox="0 0 58 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M38.3263 0.503662H19.6841C9.24974 0.503662 0.791016 8.96239 0.791016 19.3967V25.6819C0.791016 36.1163 9.24974 44.575 19.6841 44.575H38.3263C48.7606 44.575 57.2194 36.1163 57.2194 25.6819V19.3967C57.2194 8.96239 48.7606 0.503662 38.3263 0.503662Z"
                fill="#03134c"/>
            <path
                d="M29.1807 8.63753H39.9762C41.7442 8.61098 43.4946 8.99106 45.0924 9.74845C46.5894 10.4723 47.9178 11.5021 48.992 12.7713C50.0927 14.078 50.9375 15.5804 51.4821 17.1999C52.6549 20.753 52.6403 24.5911 51.4405 28.1352C50.8749 29.7628 50.0112 31.271 48.8937 32.5826C47.7943 33.8687 46.4257 34.8975 44.8847 35.5961C43.3437 36.2948 41.668 36.6463 39.9762 36.6257H29.1807V8.63753ZM33.9115 32.5335H38.8464C40.032 32.544 41.2076 32.3166 42.3038 31.8647C43.3572 31.4262 44.3024 30.7634 45.0735 29.9225C45.8851 29.0207 46.5036 27.9624 46.891 26.8127C47.3411 25.4636 47.5596 24.048 47.5372 22.626C47.5667 21.1243 47.3484 19.6281 46.891 18.1974C46.5186 17.0466 45.8982 15.9915 45.0735 15.1065C44.2996 14.3035 43.3522 13.6882 42.3038 13.3079C41.1953 12.9088 40.0245 12.7105 38.8464 12.7222H33.9115V32.5335Z"
                fill="white"/>
            <path
                d="M21.2307 39.0442C30.3441 39.0442 37.7319 31.6563 37.7319 22.543C37.7319 13.4296 30.3441 6.04175 21.2307 6.04175C12.1173 6.04175 4.72949 13.4296 4.72949 22.543C4.72949 31.6563 12.1173 39.0442 21.2307 39.0442Z"
                fill="white" stroke="#202E60" strokeWidth="0.780129" strokeMiterlimit="10"/>
            <path
                d="M21.2309 34.9477C28.2491 34.9477 33.9384 29.2584 33.9384 22.2402C33.9384 15.2221 28.2491 9.53271 21.2309 9.53271C14.2128 9.53271 8.52344 15.2221 8.52344 22.2402C8.52344 29.2584 14.2128 34.9477 21.2309 34.9477Z"
                fill="white" stroke="#1C2242" strokeWidth="0.780129" strokeMiterlimit="10"/>
            <path
                d="M26.0486 17.4268C23.925 17.6081 22.3078 17.0942 21.2309 15.8511C20.1502 17.0942 18.5329 17.6081 16.4093 17.4268C16.1977 23.6653 18.0001 27.1302 21.2309 28.6266C24.4578 27.1302 26.2602 23.6653 26.0486 17.4268Z"
                fill="#1C2242"/>
        </svg>
    );
}

export default LittleLogoMenuDeskSvg;